<template>
	<section class="domain_content">
		<!-- Disclaimer -->
		<div class="sub-page">
			<h1>Disclaimer</h1>
			<p>
				The content on <a href="https://waplusapk.download/">https://waplusapk.download/</a> is intended for informational purposes only. GB WhatsApp is a third-party modified version of WhatsApp that includes additional features and customization options not found in the official app. While we aim to provide accurate and current information about GB WhatsApp and similar apps, we cannot guarantee the security, reliability, or legality of using these modified versions. 
			</p>
			<p>
				We are not liable for any damages, data loss, or security issues that may arise from using these apps. By using this website, you acknowledge that you are responsible for your actions and understand the potential risks associated with third-party apps like GB WhatsApp.
			</p>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Disclaimer'
const description = 'Thanks for visiting our site and reading the disclaimer in this page.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://waplusapk.download/disclaimer/"
            }]
	},
}
</script>
  